import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import VideoCall from '../views/VideoCall.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/call/:callCode',
    name: 'call',
    component: VideoCall,
    props: route => ({ callCode: route.params.callCode, userName: route.query.name }) // Passa il nome utente come prop
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;