<template>
  <div class="video-container" ref="videoContainer">
    <video ref="remoteVideo" class="remote-video" autoplay playsinline></video>
    <video ref="localVideo" class="local-video" autoplay playsinline muted></video>
    <div class="controls">
      <button @click="toggleMute">
        <i :class="isMuted ? 'fas fa-microphone-slash' : 'fas fa-microphone'"></i>
      </button>
      <button @click="toggleCamera">
        <i :class="isCameraOff ? 'fas fa-video-slash' : 'fas fa-video'"></i>
      </button>
      <button @click="toggleChat" :class="{ 'has-unread': unreadMessages > 0 }">
        <i class="fas fa-comments"></i>
      </button>
      <button @click="toggleEmoticonPicker">
        <i class="fas fa-smile"></i>
      </button>
      <button @click="rotateCamera">
        <i class="fas fa-sync-alt"></i>
      </button>
      <button @click="shareScreen">
        <i class="fas fa-desktop"></i>
      </button>
      <select v-model="translationLanguage">
        <option value="">No Tran</option>
        <option value="en">EN</option>
        <option value="it">IT</option>
        <option value="es">ES</option>
      </select>
      <select v-model="subtitleLanguage" @change="changeSubtitleLanguage">
        <option value="">No Sub</option>
        <option value="en-US">Sub EN</option>
        <option value="it-IT">Sub IT</option>
        <option value="es-ES">Sub ES</option>
      </select>
    </div>
    <div v-if="showChat" class="chat-container">
      <div class="messages">
        <div v-for="(msg, index) in messages" :key="index" class="message">
          <strong>{{ msg.user }}:</strong> {{ msg.text }}</div>
      </div>
      <form @submit.prevent="sendMessage">
        <input v-model="newMessage" placeholder="Type your message here..." />
        <button type="submit">Send</button>
      </form>
    </div>
    <div v-if="incomingMessage" class="incoming-message">
      <strong>{{ incomingMessage.user }}:</strong> {{ incomingMessage.text }}
    </div>
    <div v-for="(emoji, index) in emoticons" :key="index" class="emoticon">
      {{ emoji }}
    </div>
    <div v-if="showEmoticonPicker" class="emoticon-picker">
      <span v-for="emoji in availableEmoticons" :key="emoji" @click="sendEmoticon(emoji)">
        {{ emoji }}
      </span>
    </div>
    <div v-if="subtitleLanguage && subtitleLanguage !== ''" class="subtitles">
      <div>{{ subtitlesText }}</div>
      <div v-if="translatedSubtitlesText">{{ translatedSubtitlesText }}</div>
    </div>
  </div>
</template>

<script>
import * as signalR from '@microsoft/signalr';

export default {
  name: 'VideoCall',
  data() {
    return {
      localStream: null,
      peerConnection: null,
      connection: null,
      isMuted: false,
      isCameraOff: false,
      messages: [],
      newMessage: '',
      userName: 'User', // Puoi impostarlo dinamicamente
      showChat: false,
      unreadMessages: 0,
      incomingMessage: null,
      emoticons: [],
      showEmoticonPicker: false,
      availableEmoticons: ['😀', '😂', '😍', '😎', '😭', '😡', '👍', '👎', '👏', '🙌'],
      usingFrontCamera: true, // Aggiungi un flag per tracciare la fotocamera utilizzata
      subtitlesActive: false,
      subtitlesText: '',
      recognition: null, // Aggiungi una proprietà per memorizzare l'istanza di riconoscimento vocale
      speechRecognitionSupported: ('SpeechRecognition' in window) || ('webkitSpeechRecognition' in window),
      selectedLanguage: 'en-US', // Lingua di default
      remoteSubtitlesText: '', // Aggiungi una proprietà per i sottotitoli remoti
      translationLanguage: '', // Lingua di traduzione
      translatedSubtitlesText: '', // Sottotitoli tradotti
      subtitleLanguage: '' // Lingua dei sottotitoli
    };
  },
  methods: {
    adjustVideoSize() {
      if (this.$refs.videoContainer) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    },
    toggleMute() {
      if (this.localStream) {
        this.localStream.getAudioTracks().forEach(track => {
          track.enabled = !track.enabled;
        });
        this.isMuted = !this.isMuted;
      }
    },
    toggleCamera() {
      if (this.localStream) {
        this.localStream.getVideoTracks().forEach(track => {
          track.enabled = !track.enabled;
        });
        this.isCameraOff = !this.isCameraOff;
      }
    },
    toggleChat() {
      this.showChat = !this.showChat;
      if (this.showChat) {
        this.unreadMessages = 0;
      }
    },
    toggleEmoticonPicker() {
      this.showEmoticonPicker = !this.showEmoticonPicker;
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.connection.invoke("SendMessage", this.userName, this.newMessage);
        this.newMessage = '';
      }
    },
    receiveMessage(user, message) {
      this.messages.push({ user, text: message });
      this.incomingMessage = { user, text: message };
      setTimeout(() => {
        this.incomingMessage = null;
      }, 2000);
      if (!this.showChat) {
        this.unreadMessages++;
      }
    },
    sendEmoticon(user,emoji) {
      this.connection.invoke("SendEmoticon",user, emoji);
      this.showEmoticonPicker = false;
    },
    receiveEmoticon(user,emoji) {
      this.emoticons.push(emoji);
      setTimeout(() => {
        this.emoticons.shift();
      }, 2000);
    },
    async rotateCamera() {
      this.usingFrontCamera = !this.usingFrontCamera;
      const constraints = {
        video: { facingMode: this.usingFrontCamera ? 'user' : 'environment' },
        audio: true
      };

      // Stop the current tracks
      if (this.localStream) {
        this.localStream.getTracks().forEach(track => track.stop());
      }

      try {
        this.localStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.localVideo.srcObject = this.localStream;

        // Update the peer connection with the new stream
        const videoTrack = this.localStream.getVideoTracks()[0];
        const sender = this.peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind);
        sender.replaceTrack(videoTrack);
      } catch (err) {
        console.error('Error accessing media devices.', err);
      }
    },
    async translateText(text, targetLang) {
      if (!targetLang) {
        this.translatedSubtitlesText = '';
        return;
      }

      const apiKey = 'YOUR_GOOGLE_TRANSLATE_API_KEY';
      const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          q: text,
          target: targetLang,
          format: 'text'
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      this.translatedSubtitlesText = result.data.translations[0].translatedText;
    },
    startRecognition() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = this.subtitleLanguage; // Usa la lingua dei sottotitoli selezionata

      this.recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.subtitlesText = event.results[i][0].transcript;
            this.connection.invoke("SendSubtitles", this.userName, this.subtitlesText); // Invia i sottotitoli finali
            this.translateText(this.subtitlesText, this.translationLanguage);
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        this.subtitlesText = interimTranscript;
        this.translateText(this.subtitlesText, this.translationLanguage);
      };

      this.recognition.onerror = (event) => {
        console.error('Speech recognition error', event);
      };

      this.recognition.onend = () => {
        if (this.subtitlesActive) {
          this.recognition.start();
        }
      };

      this.recognition.start();
    },
    stopRecognition() {
      if (this.recognition) {
        this.recognition.stop();
      }
      this.subtitlesText = '';
      this.remoteSubtitlesText = '';
      this.translatedSubtitlesText = '';
    },
    changeSubtitleLanguage() {
      this.subtitlesActive = this.subtitleLanguage !== '';
      if (this.subtitlesActive) {
        this.startRecognition();
      } else {
        this.stopRecognition();
      }
    },
    async shareScreen() {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        screenStream.getTracks().forEach(track => {
          this.peerConnection.addTrack(track, screenStream);
        });
        this.$refs.localVideo.srcObject = screenStream;

        screenStream.getVideoTracks()[0].onended = async () => {
          this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          this.localStream.getTracks().forEach(track => {
            this.peerConnection.addTrack(track, this.localStream);
          });
          this.$refs.localVideo.srcObject = this.localStream;
        };
      } catch (err) {
        console.error("Error sharing screen: ", err);
      }
    }
  },
  async mounted() {
    window.addEventListener('resize', this.adjustVideoSize);
    this.adjustVideoSize();

    const constraints = {
      video: { facingMode: 'user' },
      audio: true
    };

    try {
      this.localStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.$refs.localVideo.srcObject = this.localStream;
    } catch (err) {
      console.error('Error accessing media devices.', err);
      return;
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl("https://videocore.martechrevolution.com/callhub")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.connection.on("ReceiveMessage", this.receiveMessage);
    this.connection.on("ReceiveEmoticon", this.receiveEmoticon);

    this.connection.on("ReceiveSubtitles", (user, subtitles) => {
      this.remoteSubtitlesText = subtitles;
      this.translateText(subtitles, this.translationLanguage);
    });

    try {
      await this.connection.start();
    } catch (err) {
      console.error('Error while starting connection: ' + err);
    }

    this.connection.on("ReceiveOffer", async (user, offer) => {
      if (this.peerConnection) {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(offer)));
        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);
        this.connection.invoke("SendAnswer", user, JSON.stringify(answer));
      }
    });

    this.connection.on("ReceiveAnswer", async (user, answer) => {
      if (this.peerConnection) {
        await this.peerConnection.setRemoteDescription(new RTCSessionDescription(JSON.parse(answer)));
      }
    });

    this.connection.on("ReceiveIceCandidate", async (user, candidate) => {
      if (this.peerConnection) {
        await this.peerConnection.addIceCandidate(new RTCIceCandidate(JSON.parse(candidate)));
      }
    });

    const configuration = {
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
    };

    this.peerConnection = new RTCPeerConnection(configuration);

    if (this.localStream) {
      this.localStream.getTracks().forEach(track => {
        this.peerConnection.addTrack(track, this.localStream);
      });
    }

    this.peerConnection.ontrack = event => {
      this.$refs.remoteVideo.srcObject = event.streams[0];
    };

    this.peerConnection.onicecandidate = event => {
      if (event.candidate) {
        this.connection.invoke("SendIceCandidate", "user", JSON.stringify(event.candidate));
      }
    };

    if (this.peerConnection) {
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      this.connection.invoke("SendOffer", "user", JSON.stringify(offer));
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustVideoSize);
    if (this.recognition) {
      this.stopRecognition();
    }
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: black;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.remote-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.local-video {
  position: absolute;
  bottom: 80px; /* Alza il video locale sopra la barra degli strumenti */
  right: 10px; /* Mantieni un margine dal bordo destro */
  width: 120px;
  height: 120px;
  border: 2px solid white;
  border-radius: 8px;
  object-fit: cover;
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 35%;
  transform: translateX(-35%);
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
}

.controls button {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls button.has-unread {
  background-color: red;
}

.controls button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.controls i {
  font-size: 1.5em;
}

.controls select {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.chat-container {
  position: absolute;
  bottom: 150px; /* Alza la chat sopra la barra degli strumenti e il video locale */
  left: 10px;
  width: calc(100% - 20px);
  max-height: 40vh;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
}

.chat-container form {
  display: flex;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.chat-container input {
  flex: 1;
  padding: 5px;
  margin-right: 10px;
  border: none;
  border-radius: 3px;
  font-size: 16px; /* Imposta la dimensione del font a 16px */
}

.chat-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.incoming-message {
  position: absolute;
  bottom: 210px; /* Alza il messaggio in arrivo sopra la chat */
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.emoticon-picker {
  position: absolute;
  bottom: 150px; /* Alza il selettore di emoticon sopra la barra degli strumenti e il video locale */
  left: 10px;
  width: calc(100% - 20px);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.emoticon-picker span {
  cursor: pointer;
  font-size: 1.5em;
}

.emoticon {
  position: absolute;
  font-size: 3em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.subtitles {
  position: absolute;
  bottom: 80px; /* Alza i sottotitoli sopra la barra degli strumenti */
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.subtitles div {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .local-video {
    width: 100px;
    height: 100px;
    right: 10px; /* Mantieni un margine dal bordo destro */
  }

  .controls {
    bottom: 10px;
    left: 35%;
    transform: translateX(-50%);
    flex-wrap: wrap;
    justify-content: center;
  }

  .chat-container {
    width: calc(100% - 20px);
    left: 10px;
    bottom: 200px; /* Alza la chat sopra il video locale */
  }

  .emoticon-picker {
    width: calc(100% - 20px);
    left: 10px;
    bottom: 150px; /* Alza il selettore di emoticon sopra la barra degli strumenti e il video locale */
  }

  .subtitles {
    bottom: 200px; /* Alza i sottotitoli sopra la barra degli strumenti */
    font-size: 1em;
    padding: 5px;
  }
}
</style>
