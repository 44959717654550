<template>
  <div class="home">
    <div>
      <img src="../../src/assets/MartechBlu.png" width="400px">
    </div>
    <h1 style="color: #023A51;">Enter Your Call Code</h1>
    <input v-model="callCode" placeholder="Enter Call Code" />
    <input v-model="userName" placeholder="Enter Your Name" />
    <button @click="joinCall">Join Call</button>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      callCode: '',
      userName: '' // Aggiungi il campo per il nome utente
    };
  },
  methods: {
    joinCall() {
      if (this.callCode.trim() !== '' && this.userName.trim() !== '') {
        this.$router.push(`/call/${this.callCode}?name=${encodeURIComponent(this.userName)}`);
      } else {
        alert('Please enter a valid call code and your name.');
      }
    }
  }
};
</script>

<style>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
input {
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}
button {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
